import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { GeneralReportEntity } from "domain/entity/GeneralReport/GeneralReportEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import reportAxiosInstance from "../axios/reportAxiosInstance";
import { GeneralReportRepository } from "./GeneralReportRepo";

export const GeneralReportRepoImpl = (): GeneralReportRepository => {


    const initGeneralReportList = async (): Promise<string[]> => {
        const searchUrl = "/v1/initGeneralReportList"
        return axiosGetData(reportAxiosInstance, `${searchUrl}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const exportReport = async (entity: GeneralReportEntity): Promise<ResponseEntity> => {
        const exportUrl = "/v1/generalReportExport"
        return await axiosPostData(reportAxiosInstance, `${exportUrl}`, entity);
    }

    const print = async (entity: GeneralReportEntity): Promise<ResponseEntity> => {
        const printUrl = "/v1/generalReportPrint"
        return await axiosPostData(reportAxiosInstance, `${printUrl}`, entity);
    }

    const view = async (entity: GeneralReportEntity): Promise<ResponseEntity> => {
        const viewUrl = "/v1/generalReportView"
        return await axiosPostData(reportAxiosInstance, `${viewUrl}`, entity);
    }

    return {
        initGeneralReportList: initGeneralReportList,
        exportReport: exportReport,
        print: print,
        view: view,
    }
}
