import { useGeneralReportVM } from "presentation/hook/GeneralReport/useGeneralReportVM";
import { memo, useEffect, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import GeneralReportPanel from "./GeneralReportPanel";


const GeneralReportMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const generalReportVM = useGeneralReportVM();


    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    generalReportVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            generalReportVM.initGeneralReportList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [generalReportVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>

            {<>
                <GeneralReportPanel /></>}

        </div>
    </>
}

export default memo(GeneralReportMaintenance);