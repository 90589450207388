
export const GeneralReportConstant = {
    Table: {
        REPORT_TYPE: "Report",
        PRINTER: "Printer",
        FILE_LOCATION: "File Location",
        COMPANY_CODE: "Company Code",
        COMPANY_CODE_FROM: "Company Code (From)",
        COMPANY_CODE_TO: "Company Code (To)",
        CHARGE_ON_COMPANY: "Charge-on Company",
        CUSTOMER_CODE: "Customer Code",
        MASTER_COMPANY_CODE: "Master Company Code",
        ACTIVE_IND: "Active Ind.",
        DOC_DATE_FROM: "Doc Date (From)",
        DOC_DATE_TO: "Doc Date (To)",
        DOC_NO_FROM: "Doc No. (From)",
        DOC_NO_TO: "Doc No. (To)",
        PROPOSAL_NO: "Proposal No.",
        OPS_DATE_FROM: "Ops Date (From)",
        OPS_DATE_TO: "Ops Date (To)",
        GL_DATE_FROM: "GL Date (From)",
        GL_DATE_TO: "GL Date (To)",
        EXPORT_TYPE: "Export Type",
        EXPIRY_DATE_FROM: "Expiry Date (From)",
        EXPIRY_DATE_TO: "Expiry Date (To)",
        EFFECTIVE_DATE_FROM: "Effective Date (From)",
        EFFECTIVE_DATE_TO: "Effective Date (To)",
        CRITERIA_TYPE: "Criteria Type",
        CRITERIA_NAME: "Criteria Name",
        YEAR: "Year",
        FROM_YEAR: "From Year",
        TO_YEAR: "To Year",
        FROM_MONTH: "From Month",
        TO_MONTH: "To Month",
        MONTH: "Month",
        BILLING_CLERK: "Billing Clerk",
        DATE: " Date",
        EXCEPTION_DATE_FROM: "Exception Date/Time (From)",
        EXCEPTION_DATE_TO: "Exception Date/Time (To)",
        EXCEPTION_TYPE: "Exception Type",
        EDI_VIEW: "EDI View",
        CHARGE_TYPE: "Charge Type",
        ISSUED_TURNOVER_INVOICE: "Issued turnover invoice",
        OUTSTANDING_TURNOVER_TO_BE_CHARGED: "Outstanding turnover to be charged",
        ISSUED_OUTSTANDING_TURNOVER_INVOICE: "Issued turnover invoice & Outstanding turnover to be charged",
        RESERVATION: "Reservation",
        ISSUED_OUTSTANDING_RESERVATION_TURNOVER_INVOICE: "Issued turnover invoice & Outstanding turnover to be charged & Reservation",
        PROPOSAL_TYPE: "Proposal Type",
        PROPOSAL_FROM: "Proposal (From)",
        PROPOSAL_TO: "Proposal (To)",
        PROPOSAL_STATUS: "Proposal Status",
        STANDARD: "Standard",
        GN: "GN",
        BOTH: "Both",
        VERSION: "Version",
        NORMAL: "Normal",
        DIFFERENCE: "Difference",
        TARIFF_NATURE: "Tariff Nature",
        TARIFF_TYPE: "Tariff Type",
    },
    Title: {
        TITLE: "General Report",
        PDF: "PDF",
        CSV: "CSV",
        RTF: "RTF",
    },
}
