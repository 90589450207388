export interface GeneralReportEntity {

    reportType: string,
    printer: string,
    fileLocation: string,
    exportType?: string | null,
    masterCompanyCode?: string | null,
    companyCode?: string | null,
    customerCode?: string | null,
    activeInd?: string | null,
    criteriaType?: string | null,
    criteriaName?: string | null,
    docNoFrom?: string | null,
    docNoTo?: string | null,
    docDateFrom?: Date | null,
    docDateTo?: Date | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,
    proposalNo?: string | null,
    effectiveDateFrom?: Date | null,
    effectiveDateTo?: Date | null,
    expiryDateFrom?: Date | null,
    expiryDateTo?: Date | null,
    glDateFrom?: Date | null,
    glDateTo?: Date | null,
    chargeOnCompany?: string | null,
    year?: string | null,
    month?: string | null,
    exceptionDateFrom?: Date | null,
    exceptionDateTo?: Date | null,
    exceptionType?: string | null,
    ediView?: string | null,
    chargeType?: string | null,
    fromYear?: string | null,
    toYear?: string | null,
    fromMonth?: string | null,
    toMonth?: string | null,
    billingClerk?: string | null,
    date?: Date | null,
    proposalType?: string | null,
    proposalStatus?: string | null,
    version?: string | null,
    companyCodeFrom?: string | null,
    companyCodeTo?: string | null,
    proposalFrom?: string | null,
    proposalTo?: string | null,
    tariffNature?: string | null,
    tariffType?: string | null,
    issuedTurnoverInvoice?: string | null,
    outstandingTurnover?: string | null,
    issuedAndOutstadningTurnover?: string | null,
    reservation?: string | null,
    issuedAndOustandingAndReservation?: string | null,



    [key: string]: string | boolean | Date | number | null | Object | undefined
}

export const EMPTY_GENERAL_REPORT_ENTITY: GeneralReportEntity = {
    reportType: "",
    printer: "",
    fileLocation: "",
    masterCompanyCode: null,
    companyCode: null,
    customerCode: null,
    activeInd: null,
    criteriaType: null,
    criteriaName: null,
    docNoFrom: null,
    docNoTo: null,
    docDateFrom: null,
    docDateTo: null,
    opsDateFrom: null,
    opsDateTo: null,
    proposalNo: null,
    effectiveDateFrom: null,
    effectiveDateTo: null,
    expiryDateFrom: null,
    expiryDateTo: null,
    glDateFrom: null,
    glDateTo: null,
    chargeOnCompany: null,
    year: null,
    month: null,
    exceptionDateFrom: null,
    exceptionDateTo: null,
    exceptionType: null,
    ediView: null,
    chargeType: null,
    fromYear: null,
    toYear: null,
    fromMonth: null,
    toMonth: null,
    billingClerk: null,
    date: null,
    proposalType: null,
    proposalStatus: null,
    version: null,
    companyCodeFrom: null,
    companyCodeTo: null,
    proposalFrom: null,
    proposalTo: null,
    tariffNature: null,
    tariffType: null,
    issuedTurnoverInvoice: "N",
    outstandingTurnover: "N",
    issuedAndOutstadningTurnover: "N",
    reservation: "N",
    issuedAndOustandingAndReservation: "N",
    exportType: null,

}
