import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { GeneralReportProvider } from "presentation/store/GeneralReport/GeneralReportProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import GeneralReportMaintenance from "presentation/view/section/GeneralReport/GeneralReportMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const GeneralReportContMain = () => {
    return <ANAInfoWrapper permission={Permission.GENERAL_REPORT_MAINTENANCE}>
        <MessageBarWrapper>
            <GeneralReportProvider>
                <GridStyles/>
                <GeneralReportMaintenance/>
            </GeneralReportProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default GeneralReportContMain;