
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { CriteriaTypeRepository } from "./CriteriaTypeRepo";

export const CriteriaTypeRepoImpl = (): CriteriaTypeRepository => {



    const getAllCriteriaType = async (): Promise<string[]> => {
        const url = '/v1/criteriaType';
        return axiosGetData(referenceAxiosInstance, `${url}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }
    const getAllCriteriaName = async (id: number): Promise<string[]> => {
        const url = '/v1/criteriaName';
        return axiosGetData(referenceAxiosInstance, `${url}/${id}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getAllCriteriaType: getAllCriteriaType,
        getAllCriteriaName: getAllCriteriaName,
    }
}