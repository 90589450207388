export interface GeneralReportEnabledField {
    masterCompanyCode: boolean,
    companyCode: boolean,
    customerCode: boolean,
    activeInd: boolean,
    criteriaType: boolean,
    docNo: boolean,
    docDate: boolean,
    opsDate: boolean,
    proposalNo: boolean,
    effectiveDate: boolean,
    expiryDate: boolean,
    glDate: boolean,
    chargeOnCompany: boolean,
    yearInput: boolean,
    month: boolean,
    yearMonth: boolean,
    exceptionType: boolean,
    ediView: boolean,
    chargeType: boolean,
    fromYearMonth: boolean,
    monthlyRevenueCheckBox: boolean,
    billingClerk: boolean,
    date: boolean,
    proposalType: boolean,
    proposalStatus: boolean,
    version: boolean,
    proposalFromTo: boolean,
    tariff: boolean,

}

export const DEFAULT_ENABLED_GENERAL_REPORT_FIELD: GeneralReportEnabledField = {
    masterCompanyCode: false,
    companyCode: false,
    customerCode: false,
    activeInd: false,
    criteriaType: false,
    docNo: false,
    docDate: false,
    opsDate: false,
    proposalNo: false,
    effectiveDate: false,
    expiryDate: false,
    glDate: false,
    chargeOnCompany: false,
    yearInput: false,
    month: false,
    yearMonth: false,
    exceptionType: false,
    ediView: false,
    chargeType: false,
    fromYearMonth: false,
    monthlyRevenueCheckBox: false,
    billingClerk: false,
    date: false,
    proposalType: false,
    proposalStatus: false,
    version: false,
    proposalFromTo: false,
    tariff: false
}