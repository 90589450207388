import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { RemoteFileInfoRepoImpl } from "domain/repository/Common/RemoteFileInfoRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { CriteriaTypeRepoImpl } from "domain/repository/Criteria/CriteriaTypeRepoImpl";
import { GeneralReportRepoImpl } from "domain/repository/GeneralReport/GeneralReportRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { MonthEndRepoImpl } from "domain/repository/MonthEnd/MonthEndRepoImpl";
import { StaffAndVesselCodeRepoImpl } from "domain/repository/StaffAndVesselCode/StaffAndVesselCodeRepoImpl";
import { StandardProposalRepoImpl } from "domain/repository/StandardProposal/StandardProposalRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useGeneralReportTracked } from "presentation/store/GeneralReport/GeneralReportProvider";
import { GeneralReportVM } from "presentation/viewModel/GeneralReport/GeneralReportVM";
import { useMemo } from "react";

export const useGeneralReportVM = () => {
    const [, setGeneralReportState] = useGeneralReportTracked();
    const generalReportVM = useMemo(() =>
        GeneralReportVM({
            dispatch: [setGeneralReportState],
            generalReportRepo: GeneralReportRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            printQueueRepo: PrintQueueRepoImpl(),
            remoteFileInfoRepo: RemoteFileInfoRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            criteriaTypeRepo: CriteriaTypeRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            monthEndRepo: MonthEndRepoImpl(),
            staffAndVesselCodeRepo: StaffAndVesselCodeRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            standardProposalRepo: StandardProposalRepoImpl(),
        }), [setGeneralReportState])

    return generalReportVM
}