import { GeneralReportEntity } from "domain/entity/GeneralReport/GeneralReportEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/StandardProposal/StandardProposalSearchCriteria";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";
import { RemoteFileInfoRepository } from "domain/repository/Common/RemoteFileInfoRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { CriteriaTypeRepository } from "domain/repository/Criteria/CriteriaTypeRepo";
import { GeneralReportRepository } from "domain/repository/GeneralReport/GeneralReportRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { MonthEndRepository } from "domain/repository/MonthEnd/MonthEndRepo";
import { StaffAndVesselCodeRepository } from "domain/repository/StaffAndVesselCode/StaffAndVesselCodeRepo";
import { StandardProposalRepository } from "domain/repository/StandardProposal/StandardProposalRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { GeneralReportModel } from "presentation/model/GeneralReport/GeneralReportModel";
import { generalReportEnabledField } from "presentation/utils/report/ReportEnabledFieldUtils";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface GeneralReportVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<GeneralReportModel>> | ((value: SetStateAction<GeneralReportModel>) => void),
    ],
    generalReportRepo: GeneralReportRepository,
    printQueueRepo: PrintQueueRepository,
    remoteFileInfoRepo: RemoteFileInfoRepository,
    companyRepo: CompanyRepository,
    customerRepo: CustomerRepository,
    criteriaTypeRepo: CriteriaTypeRepository,
    chargeTypeRepo: ChargeTypeRepository,
    monthEndRepo: MonthEndRepository,
    staffAndVesselCodeRepo: StaffAndVesselCodeRepository,
    tariffTypeRepo: TariffTypeRepository,
    masterDataRepo: MasterDataRepository,
    standardProposalRepo: StandardProposalRepository,
}

export const GeneralReportVM = ({ dispatch, generalReportRepo, printQueueRepo, remoteFileInfoRepo, monthEndRepo, staffAndVesselCodeRepo,
    companyRepo, customerRepo, criteriaTypeRepo, chargeTypeRepo, tariffTypeRepo, masterDataRepo, standardProposalRepo }: GeneralReportVMProps) => {
    const [generalReportDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await masterDataRepo.getMasterDataByKey(MasterDataType.TAR_NATURE).then(
            tariffNatures => {
                let tariffNatureDropdownOptions = tariffNatures?.map((entity) => ({
                    dropdownLabel: entity.code,
                    tagLabel: entity.code,
                    value: entity.code,
                })) ?? []

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffNatureDropdownOptions: tariffNatureDropdownOptions,
                    }
                }))
            }
        )

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);
                let tariffTypeDropdownOptions = newTariffs?.map((entity) => ({
                    dropdownLabel: entity.tariffType,
                    tagLabel: entity.tariffType,
                    value: entity.tariffType,
                })) ?? []

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                    }
                }))
            }
        )

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOption: DropdownProps[] = [];
                // let subChargeTypeDropdownOption: { [key: string]: DropdownProps[] } = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    // if (chgTypeEty.subChargeType) {
                    //     if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                    //         subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                    //     }
                    //     subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                    //         dropdownLabel: chgTypeEty.subChargeType,
                    //         tagLabel: chgTypeEty.subChargeType,
                    //         value: chgTypeEty.subChargeType
                    //     });
                    // }
                });

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        // subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await customerRepo.getCustomerActive().then(
            customers => {
                const sortCustomers = _.orderBy(customers, ["customerCode"]);
                let customerCodeDropdownOptions: DropdownProps[] = [];
                for (let i = 0; i < sortCustomers.length; i++) {
                    if (sortCustomers[i].id) {
                        customerCodeDropdownOptions.push({
                            dropdownLabel: sortCustomers[i].customerCode,
                            tagLabel: sortCustomers[i].id.toString(),
                            value: sortCustomers[i].customerCode
                        });
                    }
                }

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        )

        await printQueueRepo.getAllPrintQueues().then(
            datas => {
                let printQueues = datas.filter(entity => entity.activeInd === 'Y');

                const dataObj = printQueues.reduce((acc, item) => {
                    const key = item.id;
                    const value = item.printQueueName;
                    acc[value] = key;
                    return acc;
                }, {} as { [key: string]: number });


                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                generalReportDispatch(prevState => ({
                    ...prevState,
                    printQueueIdMap: dataObj,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printQueueDropdownOptions: printQueueDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        printer: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )

        await remoteFileInfoRepo.getFileLoc().then(
            fileLocs => {
                let fileLocationDropdownOptions = fileLocs?.map((fileLoc) => ({
                    dropdownLabel: fileLoc,
                    tagLabel: fileLoc,
                    value: fileLoc,
                })) ?? []
                fileLocationDropdownOptions = _.orderBy(fileLocationDropdownOptions, "dropdownLabel");

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        fileLocationDropdownOptions: fileLocationDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        fileLocation: fileLocationDropdownOptions.length > 0 ? fileLocationDropdownOptions[0].value : '',
                    },
                    enabledField: {
                        ...prevState.enabledField,
                        companyCode: false,
                        billingType: false,
                        docNoFrom: true,
                        docNoTo: true,
                        docDateFrom: true,
                        docDateTo: true,
                        transactionDateFrom: false,
                        transactionDateTo: false,
                        proposalNo: false,
                        adjustmentDateFrom: false,
                        adjustmentDateTo: false
                    }
                }))
            }
        )

        await monthEndRepo.getAllMonthEndHdrYearForCombox().then(
            datas => {
                let yearDropdownOptions = datas?.map((year) => ({
                    dropdownLabel: year.toString(),
                    tagLabel: year.toString(),
                    value: year.toString(),
                })) ?? []
                yearDropdownOptions = _.orderBy(yearDropdownOptions, "dropdownLabel");

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        yearDropdownOptions: [...yearDropdownOptions],
                    }
                }))
            }
        )
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: [...companyCodeDropdownOptions],
                        chargeOnCompanyDropdownOptions: [...companyCodeDropdownOptions],
                    }
                }))
            }
        )

        await companyRepo.getCompanyByType("MASTER").then(
            datas => {
                let companies = datas.filter(entity => entity.activeInd === 'Y' && entity.status === "CONFIRMED");
                let masterCompanyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                masterCompanyCodeDropdownOption = _.orderBy(masterCompanyCodeDropdownOption, "dropdownLabel");

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        masterCompanyCodeDropdownOptions: [
                            ...masterCompanyCodeDropdownOption],
                    }
                }))
            }
        )
        await staffAndVesselCodeRepo.getEntitiesForCombox().then(
            data => {
                let billingClerkDropdownOption = data?.map((billingClerk) => ({
                    dropdownLabel: billingClerk,
                    tagLabel: billingClerk,
                    value: billingClerk,
                })) ?? []
                billingClerkDropdownOption = _.orderBy(billingClerkDropdownOption, "dropdownLabel");

                generalReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billingClerkDropdownOptions: [...billingClerkDropdownOption],
                    }
                }))
            }
        )

        await criteriaTypeRepo.getAllCriteriaType().then(
            typeList => {
                const types: string[] = typeList.map(item => {
                    const [key] = item.split(':', 2);
                    return key;
                });
                const criteriaTypeIdMap = typeList.reduce((acc, item) => {
                    const [key, value] = item.split(':', 2);
                    acc[key] = value.trim();
                    return acc;
                }, {} as { [key: string]: string });
                let criteriaTypeDropdownOption = types?.map((type) => ({
                    dropdownLabel: type,
                    tagLabel: type,
                    value: type,
                })) ?? []

                generalReportDispatch(prevState => ({
                    ...prevState,
                    criteriaTypeIdMap: criteriaTypeIdMap,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        criteriaTypeDropdownOptions: [
                            ...criteriaTypeDropdownOption],
                    }
                }))
            }
        )
    }

    const initGeneralReportList = async () => {
        await generalReportRepo.initGeneralReportList().then(data => {

            const [reportIds, reportNames] = data.reduce<[string[], string[]]>((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[0].push(key);
                acc[1].push(value.trim());
                return acc;
            }, [[], []] as [string[], string[]]);

            const dataObj = data.reduce((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[value] = key.trim();
                return acc;
            }, {} as { [key: string]: string });

            const reportTypeDropdownOption = reportNames?.map((report) => ({
                dropdownLabel: report,
                tagLabel: report,
                value: report,
            })) ?? []
            generalReportDispatch(prevState => {
                return {
                    ...prevState,
                    reportIdTypeMap: dataObj,
                    reportTypeList: reportNames,
                    reportIdList: reportIds,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reportTypeDropdownOptions: reportTypeDropdownOption
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        reportType: reportTypeDropdownOption.length > 0 ? reportTypeDropdownOption[0].value : '',
                        version: "Normal",
                        proposalType: "Both",
                        exceptionType: "CHARGE_GEN",
                        month: "01",
                    }
                }
            })
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        generalReportDispatch(prevState => {
            if (fieldKey === 'year') {
                var posIntReg = /^[1-9]\d*$/;
                if (!posIntReg.test(val)) {
                    val = prevState.currentReport[fieldKey];
                }
            }
            return {
                ...prevState,
                currentReport: {
                    ...prevState.currentReport,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onReportTypeChanged = async (reportType: string) => {
        const enabledField = generalReportEnabledField(reportType);
        generalReportDispatch(prevState => {
            return {
                ...prevState,
                enabledField: enabledField,
                currentReport: {
                    ...prevState.currentReport,
                    // reportType: prevState.dynamicOptions.reportTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.reportTypeDropdownOptions[0].value : '',
                    printer: prevState.dynamicOptions.printQueueDropdownOptions.length > 0 ? prevState.dynamicOptions.printQueueDropdownOptions[0].value : '',
                    fileLocation: prevState.dynamicOptions.fileLocationDropdownOptions.length > 0 ? prevState.dynamicOptions.fileLocationDropdownOptions[0].value : '',
                    masterCompanyCode: '',
                    companyCode: '',
                    customerCode: '',
                    activeInd: '',
                    criteriaType: '',
                    criteriaName: '',
                    docNoFrom: null,
                    docNoTo: null,
                    docDateFrom: null,
                    docDateTo: null,
                    opsDateFrom: null,
                    opsDateTo: null,
                    proposalNo: null,
                    effectiveDateFrom: null,
                    effectiveDateTo: null,
                    expiryDateFrom: null,
                    expiryDateTo: null,
                    glDateFrom: null,
                    glDateTo: null,
                    chargeOnCompany: '',
                    year: '',
                    month: '',
                    exceptionDateFrom: null,
                    exceptionDateTo: null,
                    exceptionType: '',
                    ediView: '',
                    chargeType: '',
                    fromYear: '',
                    toYear: '',
                    fromMonth: '',
                    toMonth: '',
                    billingClerk: '',
                    date: null,
                    proposalType: 'Both',
                    proposalStatus: '',
                    version: 'Normal',
                    companyCodeFrom: '',
                    companyCodeTo: '',
                    proposalFrom: '',
                    proposalTo: '',
                    tariffNature: '',
                    tariffType: '',
                    issuedTurnoverInvoice: "N",
                    outstandingTurnover: "N",
                    issuedAndOutstadningTurnover: "N",
                    reservation: "N",
                    issuedAndOustandingAndReservation: "N",
                    exportType: null,
                }
            }
        })
    }

    const onResetClick = async () => {
        generalReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                reportType: prevState.dynamicOptions.reportTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.reportTypeDropdownOptions[0].value : '',
                printer: prevState.dynamicOptions.printQueueDropdownOptions.length > 0 ? prevState.dynamicOptions.printQueueDropdownOptions[0].value : '',
                fileLocation: prevState.dynamicOptions.fileLocationDropdownOptions.length > 0 ? prevState.dynamicOptions.fileLocationDropdownOptions[0].value : '',
                masterCompanyCode: '',
                companyCode: '',
                customerCode: '',
                activeInd: '',
                criteriaType: '',
                criteriaName: '',
                docNoFrom: null,
                docNoTo: null,
                docDateFrom: null,
                docDateTo: null,
                opsDateFrom: null,
                opsDateTo: null,
                proposalNo: null,
                effectiveDateFrom: null,
                effectiveDateTo: null,
                expiryDateFrom: null,
                expiryDateTo: null,
                glDateFrom: null,
                glDateTo: null,
                chargeOnCompany: '',
                year: '',
                month: '',
                exceptionDateFrom: null,
                exceptionDateTo: null,
                exceptionType: '',
                ediView: '',
                chargeType: '',
                fromYear: '',
                toYear: '',
                fromMonth: '',
                toMonth: '',
                billingClerk: '',
                date: null,
                proposalType: 'Both',
                proposalStatus: '',
                version: 'Normal',
                companyCodeFrom: '',
                companyCodeTo: '',
                proposalFrom: '',
                proposalTo: '',
                tariffNature: '',
                tariffType: '',
                issuedTurnoverInvoice: "N",
                outstandingTurnover: "N",
                issuedAndOutstadningTurnover: "N",
                reservation: "N",
                issuedAndOustandingAndReservation: "N",
                exportType: null,
            }
        }))
    }

    const onPrintClick = async (reportIdTypeMap: { [key: string]: string }, entity: GeneralReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
        }
        return await generalReportRepo.print(data);
    }


    const onViewClick = async (reportIdTypeMap: { [key: string]: string }, entity: GeneralReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
        }
        return await generalReportRepo.view(data).then((data) => {
            if (data && data.success) {
                const res = data.data;
                if (_.isEmpty(res)) {
                    return false;
                }
                for (const key in res) {
                    if (res[key]) {
                        const tempData = res[key] as string;
                        let base64 = tempData;
                        let bstr = atob(base64);
                        let n = bstr.length;
                        let u8arr = new Uint8Array(n);
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }
                        const blob = new Blob([u8arr], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                        URL.revokeObjectURL(url);
                    }
                }
                return true;
            }
            return false;
        })
    }


    const onExportClick = async (exportType: string, reportIdTypeMap: { [key: string]: string }, entity: GeneralReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
            exportType: exportType,
        }
        return await generalReportRepo.exportReport(data);
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        generalReportDispatch(prevState => {
            return {
                ...prevState,
                currentReport: {
                    ...prevState.currentReport,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }

    const onCriteriaTypeChanged = async (criteriaId: string) => {
        await criteriaTypeRepo.getAllCriteriaName(Number(criteriaId)).then((data) => {
            let criteriaNameDropdownOption = data?.map((name) => ({
                dropdownLabel: name,
                tagLabel: name,
                value: name,
            })) ?? []
            criteriaNameDropdownOption = _.orderBy(criteriaNameDropdownOption, "dropdownLabel");

            generalReportDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    criteriaNameDropdownOptions: [
                        ...criteriaNameDropdownOption],
                },
                currentReport: {
                    ...prevState.currentReport,
                    criteriaName: "",
                }
            }))
        })
    }

    const onToYearDropChanged = async (year: string) => {
        generalReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                toMonth: "",
            }
        }))
        await monthEndRepo.getAllMonthEndHdrMonthForCombox(year).then((data) => {
            data = data.sort((a, b) => a - b);
            let monthDropdownOption = data?.map((month) => ({
                dropdownLabel: month.toString(),
                tagLabel: month.toString(),
                value: month.toString(),
            })) ?? []

            generalReportDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    monthDropdownOptions: [
                        ...monthDropdownOption],
                },
                currentReport: {
                    ...prevState.currentReport,
                    toMonth: monthDropdownOption.length > 0 ? monthDropdownOption[0].value : "",
                }
            }))
        })
    }

    const onFromYearDropChanged = async (year: string) => {
        generalReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                fromMonth: "",
            }
        }))
        await monthEndRepo.getAllMonthEndHdrMonthForCombox(year).then((data) => {
            data = data.sort((a, b) => a - b);
            let monthDropdownOption = data?.map((month) => ({
                dropdownLabel: month.toString(),
                tagLabel: month.toString(),
                value: month.toString(),
            })) ?? []

            generalReportDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    monthDropdownOptions: [
                        ...monthDropdownOption],
                },
                currentReport: {
                    ...prevState.currentReport,
                    fromMonth: monthDropdownOption.length > 0 ? monthDropdownOption[0].value : "",
                }
            }))
        })
    }
    const onYearDropChanged = async (year: string) => {
        generalReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                month: "",
            }
        }))
        await monthEndRepo.getAllMonthEndHdrMonthForCombox(year).then((data) => {
            data = data.sort((a, b) => a - b);
            let monthDropdownOption = data?.map((month) => ({
                dropdownLabel: month.toString(),
                tagLabel: month.toString(),
                value: month.toString(),
            })) ?? []

            generalReportDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    monthDropdownOptions: [
                        ...monthDropdownOption],
                },
                currentReport: {
                    ...prevState.currentReport,
                    month: monthDropdownOption.length > 0 ? monthDropdownOption[0].value : "",
                }
            }))
        })
    }

    const onCompanyFromDropChanged = async (companyFrom: string) => {
        let newSearchCriteria = { ...EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA }
        newSearchCriteria = {
            ...newSearchCriteria,
            companyCodeList: [companyFrom],
            statusList: ["FINALIZED"]
        };
        await standardProposalRepo.searchStandardProposal(newSearchCriteria).then((data) => {
            let proposalNoFromDropdownOption = data?.map((proposal) => ({
                dropdownLabel: proposal.proposalNo ?? "",
                tagLabel: proposal.proposalNo ?? "",
                value: proposal.proposalNo ?? "",
            })) ?? []
            proposalNoFromDropdownOption = _.orderBy(proposalNoFromDropdownOption, "dropdownLabel");

            generalReportDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    proposalNoFromDropdownOptions: [
                        ...proposalNoFromDropdownOption],
                },
                currentReport: {
                    ...prevState.currentReport,
                    proposalFrom: proposalNoFromDropdownOption.length > 0 ? proposalNoFromDropdownOption[0].value : "",
                }
            }))
        })
    }

    const onCompanyToDropChanged = async (companyTo: string) => {
        let newSearchCriteria = { ...EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA }
        newSearchCriteria = {
            ...newSearchCriteria,
            companyCodeList: [companyTo],
            statusList: ["FINALIZED"]
        };
        await standardProposalRepo.searchStandardProposal(newSearchCriteria).then((data) => {
            let proposalNoToDropdownOption = data?.map((proposal) => ({
                dropdownLabel: proposal.proposalNo ?? "",
                tagLabel: proposal.proposalNo ?? "",
                value: proposal.proposalNo ?? "",
            })) ?? []
            proposalNoToDropdownOption = _.orderBy(proposalNoToDropdownOption, "dropdownLabel");

            generalReportDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    proposalNoToDropdownOptions: [
                        ...proposalNoToDropdownOption],
                },
                currentReport: {
                    ...prevState.currentReport,
                    proposalTo: proposalNoToDropdownOption.length > 0 ? proposalNoToDropdownOption[0].value : "",
                }
            }))
        })
    }

    return {
        onCompanyToDropChanged: onCompanyToDropChanged,
        onCompanyFromDropChanged: onCompanyFromDropChanged,
        onFromYearDropChanged: onFromYearDropChanged,
        onToYearDropChanged: onToYearDropChanged,
        onYearDropChanged: onYearDropChanged,
        onCriteriaTypeChanged: onCriteriaTypeChanged,
        onPrintClick: onPrintClick,
        onExportClick: onExportClick,
        onViewClick: onViewClick,
        onResetClick: onResetClick,
        onReportTypeChanged: onReportTypeChanged,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        initGeneralReportList: initGeneralReportList,
        onCheckboxChange: onCheckboxChange,
    }
}